import { useEffect } from 'react';
import { useAnalytics } from '@czechtv/analytics-react';
import { useGemius } from '@components/Analytics/Gemius';

// V pripade doplneni eventy v analytics balicku je potrebne doplnit dalsi pageView typ i sem
export enum PageViewEventType {
  HomepageView = 'HomepageView',
  ListingView = 'ListingView',
  OtherView = 'OtherView',
  SearchView = 'SearchView',
  ShowView = 'ShowView',
  UserDashboardView = 'UserDashboardView',
  UserSignupView = 'UserSignupView',
}

export interface PageViewData {
  section: string;
  title: string;
  type: string;
}

export function usePageView(
  eventType: PageViewEventType,
  pageViewData: PageViewData,
  isLoading = false,
  extra?: Record<string, any>
) {
  const analytics = useAnalytics();
  const { sendPageView } = useGemius();

  useEffect(() => {
    if (isLoading) return;
    const data = {
      interaction: true,
      _clear: true,
      page: pageViewData,
      ...extra,
    };

    analytics.trigger({ type: eventType, data });
    sendPageView(eventType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, JSON.stringify(pageViewData), JSON.stringify(extra)]);

}
