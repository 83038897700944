import { useState, useEffect } from "react";
import Head from "next/head";
import { parseBooleanEnvVar } from "@czechtv/utils";
import { PageViewEventType } from "@utils/pageView";
import { PageView } from "@components/PageView";

type PageContainerProps = {
  children: React.ReactNode;
  title: string;
  description: string;
  shareImageUrl?: undefined | string;
};

export const PageContainer = ({
  children,
  title,
  description,
  shareImageUrl = undefined,
}: PageContainerProps): JSX.Element => {
  const baseTitle = "Česká televize";
  const pageTitle = `${title} | ${baseTitle}`;
  const [showPageView, setShowPageView] = useState(false);

  useEffect(() => {
    setShowPageView(!parseBooleanEnvVar(process.env.NEXT_PUBLIC_DISABLE_PAGEVIEW));
  }, []);

  const pageView = {
    //name: PageViewEventType.PageView,
    name: "PageView" as PageViewEventType,
    data: {
      title: pageTitle ? pageTitle : "Česká televize - aplikace",
      type: "type??",
      section: "Aplikace ČT",
      //category: getCategories(router.query),
      //content: {
      //  show: {
      //    id: showId,
      //    title: showTitle,
      //  },
      //},
    },
  };

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={description} />
        <link href="https://www.ceskatelevize.cz/ivysilani/favicon.ico" rel="icon" sizes="any" />
        <link
          href="https://www.ceskatelevize.cz/ivysilani/favicon.svg"
          rel="icon"
          type="image/svg+xml"
        />
        <link
          href="https://ctfs.ceskatelevize.cz/static/ivysilani/favicon.png?width=180&amp;height=180"
          rel="apple-touch-icon"
        />

        {/** facebook */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:type" content="article" />
        {shareImageUrl ? <meta property="og:image" content={shareImageUrl} /> : null}

        {/** twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@ceskatelevize" />

        {showPageView ? <PageView pageView={pageView} /> : null}
      </Head>
      {children}
    </>
  );
};
