import {
  Body18SemiBold,
} from '@czechtvPackagesOverrides/styles/fromCTV/index';
import { GRID_GAP } from '@consts/consts';

export const buttonPrimary = {
  ...Body18SemiBold,
  width: `auto`,
  border: `1px solid transparent`,
  cursor: `pointer`,
  display: `inline-flex`,
  outline: `none`,
  Position: 'relative',
  BoxSizing: `border-box`,
  transition: `background 150ms ease 0s, border-color 150ms ease 0s, box-shadow 150ms ease 0s`,
  alignItems: `center`,
  borderRadius: `${GRID_GAP * 2}px`,
  justifyContent: `center`,
  textDecoration: `none`,
  color:`rgb(255, 255, 255)`,
  background: `rgb(237, 28, 36)`,
  boxShadow: `rgba(146, 13, 18, 0.2) 0px 1px 2px 0px`,
  padding: `0px 20px`,
  height: `48px`,
  letterSpacing: `0px`,
  lineHeight: `1.5rem`,
  fontSize: `1.125rem`,
  '&:hover': {
    transition: `background 150ms ease 0s, border-color 150ms ease 0s, box-shadow 150ms ease 0s`,
    background: `rgb(191, 20, 27)`,
    boxShadow: `rgba(146, 13, 18, 0.5) 0px 1px 3px 0px`,
  },
  '&:active': {
    transition: `background 150ms ease 0s, border-color 150ms ease 0s, box-shadow 150ms ease 0s`,
    background: `rgb(191, 20, 27)`,
    boxShadow: `rgba(146, 13, 18, 0.5) 0px 1px 3px 0px`,
  }
}
