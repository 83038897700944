import React, { ReactElement } from "react";
import Link, { LinkProps } from "next/link";
import { LocalMenuNavItemLink } from "@czechtv/global-components";

export interface CustomLinkComponentProps extends React.HTMLProps<HTMLElement> {
  isNotLink?: boolean;
  customLinkProps?: any;
  active?: boolean;
  buttonAriaLabel?: string;
}

export const CustomLinkComponent = (props: CustomLinkComponentProps): ReactElement | null => {
  const { isNotLink, customLinkProps, active, buttonAriaLabel, ...rest } = props;

  // active link
  //if (active !== undefined && active === true) {
  //  console.log("active", props);
  //}

  // isNotLink
  // asi by nemelo nastat??
  //if (isNotLink !== undefined && isNotLink === true) {
  //  console.log("isNotLink", props);
  //  return null;
  //}

  // customLinkProps
  // jen pokud aktivne pouzijeme v konfiguraci menu
  //if (customLinkProps !== undefined) {
  //  console.log("customLinkProps", props);
  //}

  // buttonAriaLabel
  buttonAriaLabel && console.log('buttonAriaLabel', buttonAriaLabel)

  if (rest.href?.startsWith("http")) {
    return <a {...(rest as React.HTMLProps<HTMLAnchorElement>)} />;
  }
  return <Link {...(rest as LinkProps)} />;
};

export const setActiveInProductMenu = (
  menuItems: LocalMenuNavItemLink[] | undefined,
  activeHref: string
): LocalMenuNavItemLink[] | undefined => {
  if (menuItems == undefined) return undefined;
  return menuItems.map((menuItem: any) => {
    // funguje spatne zrejme proto, ze se menu neprekresli cele (bude nejak zavrene v useMemo?)
    /*
    if (menuItem?.items && menuItem.items.length > 0) {
      menuItem.items = setActiveInProductMenu(menuItem.items, activeHref);
    }
    */
    return activeHref.startsWith(menuItem.href) ? { ...menuItem, active: true } : menuItem;
  });
};
