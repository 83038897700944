import { projectGlobalMenuConfig } from "@components/layouts/components/Header/projectGlobalMenuConfig";
import { Blue_base } from "@czechtvPackagesOverrides/styles/fromCTV/index";
import applicationLogo from "./images/applications-logo.svg";

export const PAGES = {
  applicationsPage: {
    url: "/aplikace/",
    label: "Úvod",
  },
  applicationsMobilePage: {
    url: "/mobilni-aplikace/",
    label: "Mobilní aplikace",
  },
  applicationsHbbTVPage: {
    url: "/hbbtv/",
    label: "Červené tlačítko",
  },
  applicationsSmartTVPage: {
    url: "/smart-tv-aplikace/",
    label: "Televizní aplikace",
  },
};

const createProductMenuItems = (): any[] =>
  Object.values(PAGES).map((item: any) => {
    return { title: item.label, href: item.url };
  });

export const applicationsGlobalMenuConfig = {
  ...projectGlobalMenuConfig,
  logo: {
    title: "Aplikace ČT",
    src: applicationLogo.src,
    srcDark: applicationLogo.src,
    href: "/aplikace",
    notInvertColor: true,
  },
  style: {
    default: {
      dark: true,
      backgroundColor: "transparent",
    },
    floating: {
      dark: true,
      backgroundColor: Blue_base,
    },
  },
  productMenu: {
    items: createProductMenuItems(),
  },
};
