import { defaultConfig, WrapperPositions } from "@czechtv/global-components";

import vseOCTLogoForDark from "./logo_ct_vse_o_ct_fordark.svg";
import vseOCTLogoForLight from "./logo_ct_vse_o_ct_forlight.svg";

export const projectGlobalMenuConfig = {
  ...defaultConfig,
  ...{
    logo: {
      title: "Vše o ČT",
      src: vseOCTLogoForLight.src,
      srcDark: vseOCTLogoForDark.src,
      href: "https://www.ceskatelevize.cz/vse-o-ct/",
    },
    defaultPosition: WrapperPositions.relative,
    style: {
      default: {
        backgroundColor: "transparent",
      },
    },
    superHomepageNavVisible: false,
    search: {
      queryParameter: "q",
      url: "https://www.ceskatelevize.cz/hledani/",
    },
    productMenu: {
      items: [
        {
          title: "Lidé",
          href: "/lide/",
        },
        {
          title: "Jak sledovat",
          href: "/jak-sledovat/",
        },
        {
          title: "Galerie a prodejna",
          href: "/galerie-a-prodejna/",
        },
        {
          title: "Spolupráce",
          href: "/spoluprace/",
        },
        {
          title: "Kariéra",
          href: "/kariera/",
          items: [
            {
              title: "Kariéra v ČT",
              href: "/kariera/",
            },
            {
              title: "Volná místa",
              href: "https://ceskatelevize.jobs.cz/",
            },
            {
              title: "Možnosti uplatnění",
              href: "/kariera/moznosti-uplatneni/",
            },
            {
              title: "Studenti a stáže",
              href: "/kariera/studenti-a-staze/",
            },
            {
              title: "O výběrovém řízení",
              href: "/kariera/prubeh-vyberoveho-rizeni/",
            },
            {
              title: "Benefity",
              href: "/kariera/benefity-prace-v-ct/",
            },
            {
              title: "Kontakty",
              href: "/kariera/kontakty/",
            },
            {
              title: "Ze života v ČT",
              href: "/kariera/ze-zivota-v-ct/",
            },
            {
              title: "Konkurzy",
              href: "/kariera/konkurzy/",
            },
          ],
        },
        {
          title: "Historie",
          href: "/historie/",
        },
        {
          title: "Pro média",
          href: "/pro-media/",
        },
        {
          title: "Kontakty",
          href: "/kontakty/",
        },
      ],
      hotlinks: [
        {
          title: "Vše o ČT",
          href: "https://www.ceskatelevize.cz/vse-o-ct/",
        },
      ],
    },
  },
};
