import {
  mediaMinDesktop1024,
  Blue_base,
  Hero36Medium,
  Hero48Large,
  Header21Medium
} from '@czechtvPackagesOverrides/styles/fromCTV/index';
import { Header28Bold, Header24LargeMedium } from '@czechtvPackagesOverrides/styles'
import { GRID_GAP } from '@consts/consts';

export const HeaderH1 = {
  ...Hero36Medium,
  marginTop: '0px',
  marginBottom: `${GRID_GAP * 2}px`,
  padding: `0`,
  color: Blue_base,
  [mediaMinDesktop1024]: {
    ...Hero48Large,
  },
}

export const HeaderH2 = {
  ...Header28Bold,
  marginTop: '0px',
  marginBottom: `${GRID_GAP * 2}px`,
  padding: `0`,
  color: Blue_base,
  [mediaMinDesktop1024]: {
    ...Hero36Medium,
  },
}

export const HeaderH3 = {
  ...Header24LargeMedium,
    marginTop: '0px',
    marginBottom: `${GRID_GAP}px`,
    color: Blue_base,
    padding: `0`,
}

export const HeaderH4 = {
  ...Header21Medium,
    marginTop: '0px',
    marginBottom: `${GRID_GAP}px`,
    padding: `0`,
    color: Blue_base,
}

export const HeaderH5 = {
  ...Header21Medium,
    marginTop: '0px',
    marginBottom: `${GRID_GAP}px`,
    padding: `0`,
    color: Blue_base,
}
export const HeaderH6 = {
  ...Header21Medium,
    marginTop: '0px',
    marginBottom: `${GRID_GAP}px`,
    padding: `0`,
    color: Blue_base,
}
