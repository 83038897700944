import { CTGlobalFooter } from "@components/layouts/components/CTGlobalFooter/CTGlobalFooter";
import { Header } from "@components/layouts/components/Header/Header";
import { applicationsGlobalMenuConfig } from "./applicationsGlobalMenuConfig";

type ApplicationsLayoutProps = {
  children: React.ReactNode;
};

export const ApplicationsLayout: React.FC<ApplicationsLayoutProps> = ({ children }) => {
  return (
    <div>
      <Header globalMenuConfig={applicationsGlobalMenuConfig} />
      <div>{children ? children : null}</div>
      <CTGlobalFooter />
    </div>
  );
};
